import React, {useState} from 'react'
import Style from "./updateAccount.module.css"
import ArrowWhite from "../../assets/svg/leftArrow.svg"
import ProfilePic from "../../assets/images/profilePic.png"
import EditIcon from "../../assets/svg/pencil.svg"
import { Link } from 'react-router-dom'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Button from '../../components/button/Button';
import {useSelector } from "react-redux";
import {updateVehicle } from '../../api/service';
import {useDispatch} from 'react-redux';
import {login} from '../../_actions/index';
import {showSuccess,showError} from '../../global/globalFunction';

function UpdateAccount() {
   const [value, setValue] = useState('');
   const userObject = useSelector((state)=>state.users)
   const vehicleData=userObject.vehicles[0];
   const dispatch = useDispatch();
  
   const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.target);
       let object={ 
          _id: userObject._id,
          email:data.get('email'),
          phone:userObject.phone,
          firstname:data.get('fname'), 
          lastname:data.get('lname'), 
          username: userObject.username,
          color: vehicleData.color._id,
          make: vehicleData.make._id,
         //  model: data.get('model'),
          type: vehicleData.type._id,
          registration_number: vehicleData.registration_number,
          state:vehicleData.state,
          vin: vehicleData.vin,
          year: vehicleData.year._id,
        }
        updateVehicle(object).then(responce => {
         if(responce.Status===200){
            showSuccess(responce.Message);
            dispatch(login(responce.Data));
            localStorage.setItem('user', JSON.stringify(responce.Data));
          }
       });

    };

   return (
      <>
         <div className={Style.topWrapper}>
            <div className={Style.topWrapperText}>Update Account</div>
            <Link to="/menu">
            <img src={ArrowWhite} alt="" />
            </Link>
         </div>
         <div className={Style.imgWrapper}>
            <div className={Style.profileImg}>
               <img src={ProfilePic} alt="" />
            </div>
            <div className={Style.editProfileImg}>
               <img src={EditIcon} alt="" />
            </div>
         </div>
         <form className={Style.formWrapper} onSubmit={handleSubmit}>

         <div className={Style.formWrapper}>
            <div className={Style.formDiv}>
               <div className={Style.formLabel}>First Name</div>
               <input type="text" defaultValue={userObject.firstname} name="fname" id="fname" autoComplete="off" placeholder="Zaka" />
            </div>
            <div className={Style.formDiv}>
               <div className={Style.formLabel}>Last Name</div>
               <input type="text" name="lname" defaultValue={userObject.lastname} id="lname" autoComplete="off" placeholder="Zaka" />
            </div>
            <div className={Style.formDiv}>
               <div className={Style.formLabel}>Email</div>
               <input type="email" name="email" defaultValue={userObject.email} id="email" autoComplete="off" placeholder="Zaka" />
            </div>
            <div className={Style.formDiv}>
               <div className={Style.formLabel}>Mobile Number</div>
               <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="AE"
                value={userObject.phone}
                onChange={setValue} 
            />
            </div>
            <div className={`text-center mb-3`}>
            <Button label="Update" type="submit" cssClass={Style.btnBlack} />
         </div>
         </div>
         </form>

      </>
   )
}

export default UpdateAccount
