import {React,useState,useEffect} from 'react';
import Style from "./myVehicle.module.css";
import BackArrow from "../../assets/svg/blackArrow.svg";
import Select from "react-select";
import Button from '../../components/button/Button';
import { Link } from 'react-router-dom';
import {useSelector } from "react-redux";
import { setting,updateVehicle } from '../../api/service';
import {useDispatch} from 'react-redux';
import {login} from '../../_actions/index'
import {showSuccess,showError} from '../../global/globalFunction';


const empirates = [
   { value: 'Dubai', label: 'Dubai' },
   { value: 'Sharjah', label: 'Sharjah' },
   { value: 'Abu Dhabi', label: 'Abu Dhabi' },
   { value: 'Ajman', label: 'Ajman' },
   { value: 'Rak', label: 'Rak' },
   { value: 'Fujrah', label: 'Fujrah' },
   { value: 'UAQ', label: 'UAQ' },
   { value: 'Others', label: 'Others' },
 ];

 const years  = [
   { value: '5f05903e552aad867261f691', label: '2021' },
 ];


function RegisterForm() {
   const dispatch = useDispatch();

   const userObject = useSelector((state)=>state.users)
   const [color, setColors] = useState([]);
   const [type, setType] = useState([]);
   const [maker, setMakers] = useState([]);
   const [models, setModel] = useState([]);
   const [tempModels, settempaModels] = useState([]);
   
   const settingApi=()=>{
      setting().then(responce => {
         const colors=responce.Data.colors
         const type=responce.Data.types
         const makes=responce.Data.makes
         const models=responce.Data.models
         setColors(colors);
         setType(type);
         setMakers(makes);
         setModel(models);
         settempaModels(models)
      
       });
     }

    
  
    const handleChange = (options) => {
      console.log(options);
    };

    const handleChangeMaker = (options) => {
    const filteredModels = tempModels.filter(user => user['make_id']['make']===options.make);
    setModel(filteredModels);
    
    };

    useEffect(() => {
      settingApi();
     }, [])

     const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.target);
       let object={ 
          _id: userObject._id,
          email:userObject.email,
          phone:userObject.phone,
          firstname:userObject.firstname, 
          lastname:userObject.lastname, 
          username: userObject.username,
          color: data.get('colour'),
          make: data.get('maker'),
          model: data.get('model'),
          type: data.get('type'),
          registration_number: data.get('regNumber'),
          state:data.get('emprite'),
          vin: data.get('vin'),
          year: data.get('year'),
        }
        updateVehicle(object).then(responce => {
         if(responce.Status===200){
            showSuccess(responce.Message);
            dispatch(login(responce.Data));
            localStorage.setItem('user', JSON.stringify(responce.Data));
          }
       });

    };

    
   const [empirate, setEmirate] = useState(empirates);
  
   return (
      <>
         <div className={Style.stickyHeading}>
            <Link to="/menu">
               <img src={BackArrow} alt="backArrow" />
            </Link>
            <div className={Style.text}>My Vehicle</div>
         </div>
         <form className={Style.formWrapper} onSubmit={handleSubmit}>
         <div className={Style.formDiv}>
               <label htmlFor="emirate">Emirate</label>
               <Select name="emprite"
                  options={empirate}
                  defaultValue={empirate.find(obj => obj.value === userObject.vehicles[0].state)}
                  getOptionLabel={(empirate)=>empirate.value} 
                  getOptionValue={(empirate)=>empirate.value}
                  isSearchable={true}
                  placeholder=""
                  menuColor='black'
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="regNumber">Registration Number</label>
               <input type="number" value={userObject.vehicles[0].registration_number} name="regNumber" id="regNumber" autoComplete="off" />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="type">Type</label>
               <Select name="type"
                  options={type}
                  getOptionLabel={(type)=>type.type} 
                  getOptionValue={(type)=>type._id}
                  defaultValue={userObject.vehicles[0].type}
                  isSearchable={true}
                  placeholder=""
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="make">Make</label>
               <Select name="maker"
                  options={maker}
                  getOptionLabel={(maker)=>maker.make} 
                  getOptionValue={(maker)=>maker._id}
                  defaultValue={userObject.vehicles[0].make}
                  isSearchable={true}
                  onChange={handleChangeMaker}
                  placeholder=""
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="model">Model</label>
               <Select name="model"
                  options={models}
                  getOptionLabel={(models)=>models.model} 
                  getOptionValue={(models)=>models._id}
                  defaultValue={userObject.vehicles[0].model}
                  isSearchable={true}
                  placeholder=""
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="year">Year</label>
               <Select name="year"
                  options={years }
                  isSearchable={true}
                  defaultValue={years}
                  placeholder=""
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="colour">Colour</label>
               <Select name="colour"
                  options={color}
                  getOptionLabel={(color)=>color.color} 
                  getOptionValue={(color)=>color._id}
                  defaultValue={userObject.vehicles[0].color}
                  isSearchable={true}
                  onChange={handleChange}
                  placeholder=""
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="vin">VIN</label>
               <input type="text" value={userObject.vehicles[0].vin} name="vin" id="vin"  autoComplete="off" />
            </div>
         <div className={`text-center mb-3`}>
            <Button label="Update" type="submit" cssClass={Style.btnBlack} />
         </div>
         </form>


         
      </>
   )
}

export default RegisterForm
