import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const config = {
  apiKey: "AIzaSyABvuFgsnfL14UBPzIsJt5Bwmca2kV13do",
  authDomain: "shehnshahcustomer.firebaseapp.com",
  databaseURL: "https://shehnshahcustomer.firebaseio.com",
  projectId: "shehnshahcustomer",
  storageBucket: "shehnshahcustomer.appspot.com",
  messagingSenderId: "347468062267",
  appId: "1:347468062267:web:3c1dfbeecf45175d7e461e",
  measurementId: "G-JKNJBWRYBD",
};
firebase.initializeApp(config);
export default firebase;
