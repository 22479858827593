import React from 'react';
import Total from "../../assets/images/total.png";
import Style from "./bookingCards.module.css";
import CarWash from '../../assets/svg/car-wash.svg';
import Direction from "../../assets/svg/direction.svg";
import OilChange from "../../assets/svg/oil-change.svg";
import Battery from "../../assets/svg/battery.svg";
import {parameterdFilerArray,formatTime,formatDate} from '../../global/globalFunction'

function BookingCards(prop) {

   const cancelButton=prop.cancelButton;
   function renderImage(value)
{
   if(value!==''){
    if (value.service.title.toString()==='Oil')
    {
      return <div className={Style.inlineClass}><img src={OilChange} alt="car" /><div className={Style.text}>{value.service.title}</div></div>
    }

    if (value.service.title.toString()==='Car Wash')
    {
      return <div className={Style.inlineClass}><img src={CarWash} alt="car" /> <div className={Style.text}>{value.service.title}</div></div>
    }

    if (value.service.title.toString()==='Battery')
    {
      return <div className={Style.inlineClass}><img src={Battery} alt="car" /> <div className={Style.text}>{value.service.title}</div></div>
    }
   }
    
}



   // console.log(filteredArray)
   const organization=prop.obj.organization_id?prop.obj.organization_id:'';
   // const serviceOfferingId=prop.obj.serviceOffering_id;
   // console.log(serviceOfferingId);

   let filteredArray=parameterdFilerArray(organization.organizationServices,'_id',prop.obj.serviceOffering_id);
   const newFilerData=filteredArray[0]?filteredArray[0]:"";
   return (
      <div className={Style.cardContainer}>
         <div className={Style.cardWrapper}>
         <div className={Style.topWrappper}>
            <div className={Style.topContent}>
               <img src={Total} alt="pumpImg" className={Style.cardImg} />
               <div className={Style.cardDetail}>
                  <div className={Style.cardHeading}>{organization?organization.business_title:""}</div>
                  <div className={Style.cardLabel}>Phone: <span>{organization?organization.phone.text:""}</span></div>
                  <div className={Style.location}>{organization?organization.address.text:""}</div>
               </div>
               <img src={Direction} alt="direction" className={Style.heartGreen} />
            </div>
            <div className={Style.midContent}>
               <div className={Style.midText}>Selected Service</div>
               <div className={Style.midWrapper}>
                  <div className={Style.midWrapperLeft}>
                     {renderImage(newFilerData)}
                  </div>
                  <div className={Style.midWrapperRight}>
                     <div className={Style.heading}>{newFilerData.option.title}</div>
                     <div className={Style.description}>Reg Number: <span>{prop.obj.user_id.vehicles[0].state} - {prop.obj.user_id.vehicles[0].registration_number}</span></div>
                     <div className={Style.description}>Status: <span>{prop.obj.status_id.status}</span></div>
                  </div>
               </div>
            </div>
            <div className={Style.bottomContent}>
               <div className={`${Style.bottomWrapper} ${Style.br1}`}>
                  <div className={Style.label}>Date:</div>
                  <div className={Style.date}>{formatDate(prop.obj.date)}</div>
               </div>
               <div className={Style.bottomWrapper}>
                  <div className={Style.label}>Time:</div>
                  <div className={Style.date}>{formatTime(prop.obj.time_from)} To {formatTime(prop.obj.time_to)}</div>
               </div>
               { cancelButton ? <div onClick={() => prop.callBack(prop.obj)} className={`${Style.bottomWrapper} ${Style.cancelBtn}`}>Cancel</div> : null }
               
            </div>
         </div>
      </div>
      </div>
   )
}

export default BookingCards;
