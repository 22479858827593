import React, { useState } from 'react';
import Style from "./otp.module.css";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Link,useHistory } from "react-router-dom";
import OtpImg from "../../assets/images/otp.png"
import firebase from '../../firebase'
import ReactCodeInput from 'react-verification-code-input';
import { checkUserExist } from '../../api/service';
import {showSuccess,showError} from '../../global/globalFunction';
import {login} from '../../_actions/index'
import {useDispatch} from 'react-redux';
import {useSelector } from "react-redux";
import Loader from "react-loader-spinner";

function Otp() {
  const data = useSelector((state)=>{
  return state.users
  })
   const history = useHistory();
   const dispatch = useDispatch();


    const [phNo, setValue] = useState('');
    const [code, setCode] = useState('');
    const [title, setTitle] = useState('Verify Your Number');
    const [description, setDescription] = useState('Please enter your mobile number to receive one Time Password (OTP)');
    const [sentOtp, setOtp] = useState(true);
    const [loader, setButtonLoader] = useState(false);


   
    const handleClick = (e) => {
       setButtonLoader(true)
        const phoneNumber = phNo
        const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha',{
            'size': 'invisible',
        });
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              setOtp(false);
              setButtonLoader(false)
              setTitle('Enter Passcode');
              setDescription('OTP has been sent to your mobile number.Please enter Passcode');
              // ...
            }).catch((error) => {
              // Error; SMS not sent
              // ...
              console.log("SMS not sent",error)
            });
      }

     const verifyOtp = (e) =>{
      setButtonLoader(true)

        window.confirmationResult.confirm(code).then((result) => {

            let object ={
                phone:phNo
            }
            checkUserExist(object).then(responce => {
              setButtonLoader(false)
                if(responce.Data.is_exist){
                  dispatch(login(responce.Data.user));
                  localStorage.setItem('user', JSON.stringify(responce.Data.user));
                   history.push({
                      pathname: '/services',
                    })
                }else{
                  history.push({
                    pathname: '/register',
                    state: { pNo: phNo }
                  })
                }
          
              });
          // User signed in successfully.
          const user = result.user;
          console.log(JSON.stringify(user))
          // ...
        }).catch((error) => {
            setCode('')
            setButtonLoader(false)
            alert('Invalid Otp Code')
          // User couldn't sign in (bad verification code?)
          // ...
        });
      }
  
    return (
        <div className={Style.customContainer}>
            <div className={Style.wrapper}>
            <div className={`${Style.otpImg} pt-2`} >
                <img src={OtpImg} alt="otp-img" />
            </div>
                <div className={`${Style.heading} pt-3`}>{title}</div>
                <div className={`${Style.subHeading} pt-2 text-center`}>{description}</div>
            </div>
            <div id="recaptcha"></div>
            {
             sentOtp ? <div className={`${Style.headingSm} text-left px-4`}>Mobile number</div>:null

            }
         { sentOtp ? <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="AE"
                value={phNo}
                onChange={setValue} 
            /> : <ReactCodeInput value={code} onChange={setCode}  fields={6} className={`d-flex justify-content-center mt-5 w-100`} /> }

            
            <div className={`text-center pt-5`}>
            {
             sentOtp ? 
             <button className="btnBlack" onClick={()=>{handleClick()}}>Send
             {
               loader ? 
             <span>
               <Loader
             type="Circles"
             color="#00BFFF"
             height={27}
             width={27}
           /></span>:null
             }
           </button>:<button className="btnBlack" onClick={()=>{verifyOtp()}}>Verify
           {
               loader ? 
             <span>
               <Loader
             type="Circles"
             color="#00BFFF"
             height={27}
             width={27}
           /></span>:null
             }</button>
            }
            </div>
            
            
        </div>

    )
}

export default Otp;
