import {React,useState} from 'react'
import Style from "./confirmBooking.module.css";
import "../../components/CardRating"
import { Link } from "react-router-dom";
import Cover from "../../assets/images/cover.png";
import Profile from "../../assets/images/profile.png";
import ArrowWhite from "../../assets/svg/arrow-white.svg";
import CardRating from '../../components/CardRating';
import CarWash from "../../assets/svg/detailCar.svg"
import AddressIcon from '../../assets/svg/addressIcon.svg';
import VehicleIcon from '../../assets/svg/vehicleIcon.svg';
import VinIcon from '../../assets/svg/vinIcon.svg';
import Dubai from  "../../assets/svg/dubai.svg" 
import {useHistory } from 'react-router-dom';
import OilChange from "../../assets/svg/detailOil.svg";
import Battery from "../../assets/svg/detailBattery.svg";
import {formatTime,formatDate,showSuccess,showError,handleRoute} from '../../global/globalFunction';
import { createBooking } from '../../api/service';
import { toast, Zoom } from "react-toastify";
import {useSelector } from "react-redux"

import "react-toastify/dist/ReactToastify.css";
toast.configure();
function ConfirmBooking() {
   const loginUser = useSelector((state)=>{
      return state.users
      })
      console.log(loginUser);
//   const [setBookingVlaue, setBookingData] = useState(renderImage(useData.service.title));

   const history = useHistory();
   const data = history.location.state.data;
   const organization=data.organizationData.organization
   const useData=data.organizationData.object

   const renderImage = (title) =>{
      if (title==='Oil')
      {
        return <div className="detailCard" ><img src={OilChange} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
  
      if (title==='Car Wash')
      {
        return <div className="detailCard" ><img src={CarWash} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
  
      if (title==='Battery')
      {
        return <div className="detailCard" ><img src={Battery} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
      
  }

  const booking=()=>{
   
     let object={
      user_id: loginUser._id,
      queue_id: data.slotsData.queue_id,
      vehicle_id: loginUser.vehicles[0]._id,
      organization_id: data.organizationData.organization._id,
      serviceOffering_id: data.organizationData.object._id,
      auth_user_id: loginUser._id,
      date: formatDate(data.selectedDate),
      time_from: data.slotsData.timeRange.start,
      time_to: data.slotsData.timeRange.end
    }

    
   createBooking(object).then(responce => {

      if(responce.Status==200){
         showSuccess(responce.Message);

         history.push({
            pathname: '/services',
          })
      }else{
         showError(responce.Message);
      }

    });
  
  }

  const [services, setServices] = useState(renderImage(useData.service.title));

   return (
      <>
         <div className="fullContainer">
            <div className={Style.profileWrapper}>
               <div className={Style.coverImg}>
                  <img src={Cover} alt="coverImg" className={Style.coverPhoto} />
               </div>
               <div className={Style.profileImg}>
                  <img src={Profile} alt="profileImg" className={Style.profilePhoto} />
                  <div className={Style.detailDesc}>
                     <div className={Style.detailHeading}>{organization.business_title}</div>
                     <div className="detailaddress">{organization.phone}</div>
                     <div className="detailaddress">{organization.address}</div>
                  </div>
               </div>
               
            </div>
            <div className={Style.serviceText}>
               Select Service
            </div>
            <div className={Style.confirmBookingCardWrapper}>
               <div className={Style.confirmBookingCard}>
                  { services}
               </div>
               <div className={Style.confirmBookingCardRightText}>{useData.option.title}</div>
            </div>
            <div className={Style.timingWrapper}>
               <div className={Style.date}>
                  <span className={Style.timingWrapperLabel}>Date: </span><span className={Style.timingWrapperText}>{formatDate(data.selectedDate)}</span>
               </div>
               <div className={Style.border}></div>
               <div className={Style.time}>
                  <span className={Style.timingWrapperLabel}>Time: </span><span className={Style.timingWrapperText}>{formatTime(data.slotsData.timeRange.start)}</span>
               </div>
            </div>
            <div className={Style.serviceBottom}>
               <div className={Style.numberPlate}>
                  <div className={Style.numberPlateLeft}>C</div>
                  <img src={Dubai} alt="dubai" />
                  <div className={Style.numberPlateLeft}>19001</div>

               </div>
               <div className={Style.serviceBottomDetail}>
                  <img src={VehicleIcon} alt="addressImg" className={Style.serviceIcon} />
                  <div><span className={Style.bottomLabel}>Address: </span>{organization.address}</div>
               </div>
               <div className={Style.serviceBottomDetail}>
                  <img src={VinIcon} alt="addressImg" className={Style.serviceIcon} />
                  <div><span className={Style.bottomLabel}>Website: </span>{organization.website}</div>
               </div>
               <div className={Style.serviceBottomDetail}>
                  <img src={AddressIcon} alt="addressImg" className={Style.serviceIcon} />
                  <div><span className={Style.bottomLabel}>Phone: </span>{organization.phone}</div>
               </div>
            </div>
               <div className={`text-center pt-5 pb-5`}>
                  <button onClick={()=>{booking()}} className="btnBlack">Confirm Booking</button>
               </div>
            
         </div>
      </>
   )
}

export default ConfirmBooking;
