
import Style from "./services.module.css";
import ServiceCards from "../../components/serviceCards/ServiceCards";
import {useHistory } from 'react-router-dom';
import Spinner from '../../components/spinner/index'
import { serviceProvider } from '../../api/service';
import React, { useEffect, useState } from 'react';
import {useSelector } from "react-redux";

function Services() {
   const data = useSelector((state)=>state.users)
   const history = useHistory();
   const [list, setList] = useState([]);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      serviceProvider().then(responce => {
         const listed=responce.Data.organizations
         setList(listed);
         setLoading(false);
   
       });
        
    }, [])

   //  const handleRoute = (object) =>{ 
   //    //  console.log(object.organizationServices);
   //    //  console.log(object.organizationServices);
   //    history.push({
   //       pathname: '/detail',
   //       state: { apiObject: object }
   //     })
   //  }


   return (
      <>
         <div className={Style.home}>
         <div className="topHeading">Service Providers</div>
         <div className={Style.cardMain}>
         { loading ? <Spinner /> : null }

            {list.map(function(object, i){


        return (
        <>
         {/* <div onClick={()=>{handleRoute(object)}} className={Style.serviceLink}> */}
        <ServiceCards obj={object} key={i} 
        />
         {/* </div> */}
        </>
        )
})}
         </div>
      </div>
         
      </>
   )
}

export default Services
