import {React,useState,useEffect} from 'react'
import Style from "./registerForm.module.css";
import BackArrow from "../../assets/svg/blackArrow.svg"
import Select from "react-select"
import Button from '../button/Button';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { setting,updateVehicle,addUserData } from '../../api/service';
import {showSuccess,showError} from '../../global/globalFunction';
import {useDispatch} from 'react-redux';
import {login} from '../../_actions/index'


 const empirates = [
   { value: 'Dubai', label: 'Dubai' },
   { value: 'Sharjah', label: 'Sharjah' },
   { value: 'Abu Dhabi', label: 'Abu Dhabi' },
   { value: 'Ajman', label: 'Ajman' },
   { value: 'Rak', label: 'Rak' },
   { value: 'Fujrah', label: 'Fujrah' },
   { value: 'UAQ', label: 'UAQ' },
   { value: 'Others', label: 'Others' },
 ];

 const years  = [
   { value: '5f05903e552aad867261f691', label: '2021' },
 ];


function RegisterForm() {

   const dispatch = useDispatch();
   const history = useHistory();
   const [color, setColors] = useState([]);
   const [type, setType] = useState([]);
   const [maker, setMakers] = useState([]);
   const [models, setModel] = useState([]);
   const [tempModels, settempaModels] = useState([]);


   useEffect(() => {
      settingApi();
     }, [])


   const settingApi=()=>{
      setting().then(responce => {
         const colors=responce.Data.colors
         const type=responce.Data.types
         const makes=responce.Data.makes
         const models=responce.Data.models
         setColors(colors);
         setType(type);
         setMakers(makes);
         setModel(models);
         settempaModels(models)
      
       });
     }

     const handleChangeMaker = (options) => {
      const filteredModels = tempModels.filter(user => user['make_id']['make']===options.make);
      setModel(filteredModels);
      
      };

      const onSubmit = (event) => {
         event.preventDefault();
         const data = new FormData(event.target);
          let object={ 
             email:data.get('email'),
             phone:data.get('number'),
             firstname:data.get('fname'), 
             lastname:data.get('lname'), 
             username: data.get('fname')+' '+data.get('lname'),
             color: data.get('colour'),
             make: data.get('maker'),
             model: data.get('model'),
             type: data.get('type'),
             registration_number: data.get('regNumber'),
             state:data.get('emprite'),
             vin: data.get('vin'),
             year: data.get('year'),
           }
           addUserData(object).then(responce => {
              console.log(responce);
            if(responce.Status===200){
               showSuccess(responce.Message);
               dispatch(login(responce.Data));
               localStorage.setItem('user', JSON.stringify(responce.Data));
               history.push({pathname: 'services'});
             }
          });
   
       };

   const phonN = history.location.state ? history.location.state.pNo : "";
   console.log(phonN);
   if(history.location.state===undefined){
      history.push({pathname: '/'});
      return <></>;
  }
   return (
      <>
         <div className={Style.stickyHeading}>
            <Link to="/booking">
               <img src={BackArrow} alt="backArrow" />
            </Link>
            <div className={Style.text}>Register</div>
         </div>
         <form className={Style.formWrapper} onSubmit={onSubmit}>
            <div className={Style.formDiv}>
               <label htmlFor="fname">First name</label>
               <input type="text" name="fname" id="fname" placeholder="Enter your first name" autoComplete="off" />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="lname">Last name</label>
               <input type="text" name="lname" id="lname" placeholder="Enter your last name" autoComplete="off" />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="email">Email</label>
               <input type="email" name="email" id="email" placeholder="Enter your email" autoComplete="off" />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="number">Phone number</label>
               <input readOnly type="text" defaultValue={phonN} name="number" id="number" placeholder="Enter your phone number" autoComplete="off" />
            </div>

            <div className={Style.formDiv}>
               <label htmlFor="emirate">Emirate</label>
               <Select name="emprite"
                  options={empirates}
                  isSearchable={true}
                  placeholder="Select emirate"
                  menuColor='black'
               />
            </div>
   
            <div className={Style.formDiv}>
               <label htmlFor="regNumber">Registeration</label>
               <input type="number" name="regNumber" id="regNumber" placeholder="Enter your registeration" autoComplete="off" />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="type">Type</label>
               <Select name="type"
                  options={type}
                  getOptionLabel={(type)=>type.type} 
                  getOptionValue={(type)=>type._id}
                  isSearchable={true}
                  placeholder="Select Type"
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="make">Make</label>
               <Select name="maker"
                  options={maker}
                  getOptionLabel={(maker)=>maker.make} 
                  getOptionValue={(maker)=>maker._id}
                  isSearchable={true}
                  onChange={handleChangeMaker}
                  placeholder="Select Make"
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="model">Model</label>
               <Select name="model"
                  options={models}
                  getOptionLabel={(models)=>models.model} 
                  getOptionValue={(models)=>models._id}
                  isSearchable={true}
                  placeholder="Select Model"
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="year">Year</label>
               <Select name="year"
                  options={years }
                  isSearchable={true}
                  defaultValue={years}
                  placeholder=""
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="colour">Colour</label>
               <Select name="colour"
                  options={color}
                  getOptionLabel={(color)=>color.color} 
                  getOptionValue={(color)=>color._id}
                  isSearchable={true}
                  placeholder="Select Colour"
               />
            </div>
            <div className={Style.formDiv}>
               <label htmlFor="vin">VIN</label>
               <input type="text" name="vin" id="vin" placeholder="Enter VIN number" autoComplete="off" />
            </div>
            <div className={`text-center mb-3`}>
            <Button  label="Register" type="submit" cssClass={Style.btnBlack} />
         </div>
         </form>
         

         
      </>
   )
}

export default RegisterForm
