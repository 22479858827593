import moment from 'moment'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory } from 'react-router-dom';

toast.configure();

export  function filterArray(array,value) {
    let filtered = array.filter(t=>t.option.title ===value);
    return filtered;
  }

  export function  parameterdFilerArray(array,key,value) {
    const filteredData = array.filter(user => user[key]===value);
    return filteredData;
    // code to use filterdData, presumably using this.setState somewhere
}

export function  historyBookings(array) {
  let start = new Date();
  const filteredData = array.filter(item => {
    let date = new Date(item.time_to);
    return date <= start || item.status_id.status==='Cancelled' ;
 });
  return filteredData;
  // code to use filterdData, presumably using this.setState somewhere
}

// export function sortArray(array,order)

export function  currentBookings(array) {
  let start = new Date();
  const filteredData = array.filter(item => {
    let date = new Date(item.time_to);
    return date >= start && item.status_id.status!=='Cancelled';
 });
  return filteredData;
}

  export const formatTime=(dateString)=>{
    const local = moment.utc(dateString).local().format("hh:mm A");
    return local;
  }

  export const dateTimeFormat=(dateString)=>{
    const local = moment.utc(dateString).local().format("YYYY-MM-DD hh:mm a");
    return local;
  }

  export const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  export const showSuccess = (message) => {
    toast.success(message);
  };

  export const showError = (message) => {
    toast.error(message);
  };


  export const showInfo = (message) => {
    toast.info(message);
  };

  export const showWarn = (message) => {
    toast.warn(message);
  };

  export const handleRoute = (route) => {
   useHistory.push({
      pathname:route,
    })
  };



  

  