import {React,useState,useEffect} from 'react'
import Style from "./bookingSlot.module.css"
import BlackArrow from "../../assets/svg/blackArrow.svg"
import Calendar from "../../assets/svg/calendarIcon.svg";
import BookingSlotWrapper from '../../components/bookingSlotWrapper/BookingSlotWrapper';
import Battery from "../../assets/svg/detailBattery.svg";
import OilChange from "../../assets/svg/detailOil.svg";
import CarWash from "../../assets/svg/detailCar.svg";
import {useHistory } from 'react-router-dom';
import { availabelSlots } from '../../api/service';
import DateFnsUtils from '@date-io/date-fns';
import Spinner from '../../components/spinner/index'

import {MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import moment from "moment";



function BookingSlot(props) {
   const [selectedDate, setSelectedDate] = useState(new Date());
   const [slots, setSlots] = useState([]);
   const [loading, setLoading] = useState(true);
   const dateString = new Date();
   
   const formatDate = (dateString) => {
      const options = { year: "numeric", month: "long", day: "numeric" }
      return new Date(dateString).toLocaleDateString(undefined, options)
    }


   // const [services, setServices] = useState(loadServices());


   const renderImage = (title) =>{
      if (title==='Oil')
      {
        return <div className="detailCard" ><img src={OilChange} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
  
      if (title==='Car Wash')
      {
        return <div className="detailCard" ><img src={CarWash} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
  
      if (title==='Battery')
      {
        return <div className="detailCard" ><img src={Battery} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
      
  }
   const history = useHistory();
   const apiObject = history.location.state.apiObject;
   const data={
      organization_id:apiObject.organization._id, 
      service_id:apiObject.object._id,
      date:moment(dateString).format("YYYY-MM-DD")
   }

   // console.log(data);


  


   const handleDateChange = (date) => {
      setLoading(true);

      setSelectedDate(date);
     
      const data={
         organization_id:apiObject.organization._id, 
         service_id:apiObject.object._id,
         date:moment(date).format("YYYY-MM-DD")
      }
      availabelSlots(data).then(responce => {
         setSlots(responce.Data);
         setLoading(false);

         // console.log(responce.Data);
       });
   };

   useEffect(() => {
      availabelSlots(data).then(responce => {
         setSlots(responce.Data);
         setLoading(false);

         // console.log(responce.Data);
       });
        
    }, [])
    const handleRoute = (object,object2) =>{ 
       console.log(object2);
       let data={
          slotsData:object,
          organizationData:object2,
          selectedDate:selectedDate,
       }
       console.log(object);
      //  console.log(object.organizationServices);
      //  console.log(object.organizationServices);
      history.push({
         pathname: '/confirmBooking',
         state: { data: data }
       })
    }
   const [services, setServices] = useState(renderImage(apiObject.object.service.title));

   return (
      <div  className={Style.fullContainer}>
         <div className={Style.slotsTopWrapper}>
            <div className={`d-flex justify-content-between align-items-center`}>
               <img src={BlackArrow} alt="arrow-white" />
               <div className={Style.slotsTopText}>Available Slots</div>
               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                     value={selectedDate}
                     minDate={moment().toDate()}
                     onChange={handleDateChange}
                  />
               </MuiPickersUtilsProvider>
            </div>
         </div>
         <div className={Style.slotsDate}>
            <div className={Style.slotsDateText}>
              <div className={Style.carWrapper}>
              { services}
              <div className={`pl-3`}>{apiObject.object.option.title}</div>
              </div>

               <div className={Style.dateWrapper}>
                  <span className={Style.today}>Today</span>
                  <span className={Style.date}>{formatDate(dateString)}</span>
               </div>
            </div>
         </div>


         { loading ? <Spinner /> : null }

            {slots.map(function(object, i){

        return (
        <>
         <div onClick={()=>{handleRoute(object,apiObject,selectedDate)}} className={Style.serviceLink}>
        <BookingSlotWrapper obj={object} key={i} 
        />
         </div>
        </>
        )
})}
         
         
      </div>
   )
}

export default BookingSlot
