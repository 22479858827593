import React from 'react'
import Style from  "./bookingSlotWrapper.module.css";
import SlotOrange from "../../assets/svg/slotO.svg"
import {Link} from "react-router-dom"
import Button from "../../components/button/Button";
import {formatTime} from '../../global/globalFunction';
import {useHistory } from 'react-router-dom';

function BookingSlotWrapper(props) {
   const history = useHistory();
   const handleRoute = (object) =>{ 
      //  console.log(object.organizationServices);
      //  console.log(object.organizationServices);
      history.push({
         pathname: '/confirmBooking',
       })
    }
   console.log(props.obj.timeRange.start);
   return (
      <>
        <div className={Style.slotsBottomWrapper}>
            <div className={`d-flex align-items-center justify-content-around pb-3`}>
               <div className={Style.slotImgWrapper}>
                  <img src={SlotOrange} alt="orange-slot" className={Style.slotImg} />
                  <div className={Style.slotNumber}>01</div>
               </div>
               <div className={Style.time}>{formatTime(props.obj.timeRange.start)}</div>
               <div className={Style.time}>{formatTime(props.obj.timeRange.end)}</div>
               <div className={Style.slotsBtnWrapper}>
               <Button onClick={()=>{handleRoute(props)}} label="Book" type="button" cssClass={Style.borderOrange} />
               </div>
            </div>
         </div> 
      </>
   )
}

export default BookingSlotWrapper
