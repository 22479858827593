import {React,useState,useEffect} from 'react'
import Style from './bookings.module.css'
import BlackArrow from '../../assets/svg/blackArrow.svg'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import BookingCards from '../../components/bookingCards/BookingCards';
import {useSelector } from "react-redux";
import { bookingList,bookingStatus } from '../../api/service';
import Spinner from '../../components/spinner/index'
import {currentBookings,dateTimeFormat,historyBookings} from '../../global/globalFunction'

function Bookings() {

   const [currentBooking, setCurrentBooking] = useState([]);
   const [historyBooking, sethistoryBooking] = useState([]);
   const [loading, setLoading] = useState(true);
   const data = useSelector((state)=>{
      return state.users
     })
   

    const callBackFromChild = (prop) =>{ 

      let object={
         user_id:data._id,
         booking_id:prop._id,
         status_id:"5f3a2f4fc1374e16706dd29c"
      }

      bookingStatus(object).then(responce => {
         setLoading(true);
         if(responce.Status===200){
            bookingListApi();
         }
       });
    }
    const bookingListApi=()=>{
      let object={
         user_id:data._id
      }
      bookingList(object).then(responce => {
         const listed=responce.Data
         const currentBooking=currentBookings(listed,'time_to',dateTimeFormat(new Date()));
         const historyBooking=historyBookings(listed,'time_to',dateTimeFormat(new Date()));
        currentBooking.sort((a, b) => a.time_to.localeCompare(b.time_to));
        historyBooking.sort((a, b) => a.time_to.localeCompare(b.time_to));
         setCurrentBooking(currentBooking);
         sethistoryBooking(historyBooking);
         setLoading(false);
   
       });
     }
   useEffect(() => {

     bookingListApi();
    }, [])
   
   return (
      <>
        <div className={Style.home}>
            <div className={Style.topHeading}><img src={BlackArrow} alt="arrow" className={`mr-3`} />Bookings</div>
            <Tabs>
               <TabList>
                  <Tab>Schedule</Tab>
                  <Tab>History</Tab>
               </TabList>

               <TabPanel>
               { loading ? <Spinner /> : null }

           {currentBooking.map(function(object, i){

            return (
            <>
            {/* <div onClick={()=>{handleRoute(object)}} className={Style.serviceLink}> */}
            <BookingCards cancelButton={true}  obj={object} key={i} callBack={callBackFromChild}
            />
            {/* </div> */}
            </>
            )
            })}
               </TabPanel>
               <TabPanel>
               {historyBooking.map(function(object, i){

               return (
               <>
               {/* <div onClick={()=>{handleRoute(object)}} className={Style.serviceLink}> */}
               <BookingCards obj={object} key={i} cancelButton={false} callBack={callBackFromChild}
               />
               {/* </div> */}
               </>
               )
               })}
               </TabPanel>
            </Tabs>
         </div>
      </>
   )
}

export default Bookings
